.App {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #566573;
  min-height: 80vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}
h1 {
  font-weight: 800;
}

h2,
h3 {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: #f1948a;
}

@keyframes slide-up {
  from {
    opacity: 0;
    bottom: -30px;
  }
  to {
    bottom: -1px;
    opacity: 1;
  }
}

@keyframes load6 {
  0% {
    width: 0;
    color: transparent;
  }
  90% {
    width: 60%;
    color: transparent;
  }
  100% {
    color: white;
  }
}

@keyframes load7 {
  0% {
    width: 0;
    color: transparent;
  }
  90% {
    width: 70%;
    color: transparent;
  }
  100% {
    color: white;
  }
}

@keyframes load8 {
  0% {
    width: 0;
    color: transparent;
  }
  90% {
    width: 80%;
    color: transparent;
  }
  100% {
    color: white;
  }
}

@keyframes load9 {
  0% {
    width: 0;
    color: transparent;
  }
  90% {
    width: 90%;
    color: transparent;
  }
  100% {
    color: white;
  }
}

@media (max-width: 768px) {
  .App {
    padding: 0;
  }
}
